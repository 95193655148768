import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import api from "api";
import { lstMenu } from "config";

const ProtectedRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [path, setPath] = useState(window.location.pathname.toLowerCase());
  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
    const checkAuthorization = async () => {
      try {
        var data = await api.get("User/me");
        if (data.code === 1) {
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
        }
      } catch (error) {
        setIsAuthorized(false);
      }
      setIsLoading(false);
    };

    checkAuthorization();
  }, [location]);

  if (isLoading) {
    return <div>Loading...</div>; // Bạn có thể thay thế bằng component loading khác nếu muốn
  }
  if (path == "/login") return <Outlet />;

  if (!isAuthorized) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
