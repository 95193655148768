import React from "react";
import { Button, Table, Card, DatePicker, Tag } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import api from "api";
import dayjs from "dayjs";
import { formatCurrency, formatLongDate, formatShortDate } from "common/Format2";
import { EditOutlined } from "@ant-design/icons";
import { Print } from "component/print";
import History from "component/print/history";
import { animateScroll as scroll } from "react-scroll";
import { Header } from "Client";

const CustomerHistory = (props) => {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [listData, setListData] = React.useState([]);
  const [mode, setMode] = React.useState("web");
  const { id } = useParams();
  const navigate = useNavigate();
  const { header, setShowHeader } = React.useContext(Header);

  React.useEffect(() => {
    getLastTransaction();
  }, []);

  React.useEffect(() => {
    if (startDate && endDate) getData();
  }, [startDate, endDate]);

  const getLastTransaction = () => {
    api.get("Customer/GetLastTransaction?customerID=" + id).then((res) => {
      if (res.data) {
        setStartDate(dayjs(res.data.create_date));
        setEndDate(dayjs(res.data.create_date));
      }
    });
  };

  const getData = () => {
    var lstDay = [{ date: startDate }];
    var start = dayjs(startDate);
    while (start < endDate) {
      start = start.add(+1, "day");
      lstDay.push({ date: start });
    }
    api
      .post("Customer/GetListTransaction", { CustomerID: id, StartDate: startDate.format("YYYY/MM/DD"), endDate: endDate.format("YYYY/MM/DD") })
      .then((res) => {
        lstDay.forEach((element) => {
          console.log(element.date);
          element.lst = res.data.filter(
            (x) =>
              dayjs(x.create_date).date() == element.date.date() &&
              dayjs(x.create_date).month() == element.date.month() &&
              dayjs(x.create_date).year() == element.date.year()
          );
        });
        setListData(lstDay);
      });
  };

  const beforePrint = () => {
    setMode("print");
    setShowHeader(false);
  };
  const afterPrint = () => {
    setMode("web");
    setShowHeader(true);
  };

  return (
    <div>
      {mode == "web" ? (
        <>
          <div>
            <div>
              <div style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
                <div style={{ flex: 1 }}>
                  <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setStartDate} value={startDate} />
                </div>
                <div style={{ display: "flex", margin: 5 }}>
                  <div style={{ margin: "auto" }}>Đến</div>
                </div>
                <div style={{ flex: 1 }}>
                  <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setEndDate} value={endDate} />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 50 }}>
            <History getData={getData} mode="Customer" type="web" listData={listData}></History>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", position: "fixed", bottom: 20, left: 20, right: 20, gap: 20 }}>
            <Print type="history" listData={listData} style={{ flex: 1 }} header={header} afterPrint={afterPrint} beforePrint={beforePrint} />
            <Button
              type="primary"
              size="large"
              style={{ flex: 1 }}
              onClick={() => {
                scroll.scrollToBottom();
              }}
            >
              End
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                navigate("/");
              }}
              style={{ flex: 1 }}
            >
              Home
            </Button>

            <Button
              type="primary"
              size="large"
              style={{ flex: 1 }}
              onClick={() => {
                navigate("/customer_transaction/" + id);
              }}
            >
              Trở lại
            </Button>
          </div>
        </>
      ) : (
        <History listData={listData} style={{ flex: 1 }} header={header} />
      )}
    </div>
  );
};
export default CustomerHistory;
