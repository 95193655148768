import React from "react";
import { Button, Checkbox, Radio, Modal, Card, Tabs, InputNumber, Select, message } from "antd";
import api from "api";
import { useParams } from "react-router-dom";

const PopupEditPay = React.forwardRef((props, ref) => {
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [type, setType] = React.useState();
  const [title, setTitle] = React.useState();
  const [payData, setPayData] = React.useState({});
  const { id } = useParams();
  const [customer, setCustomer] = React.useState({});

  React.useImperativeHandle(ref, () => ({
    open(type, data) {
      data.price = Math.abs(data.price);
      setPayData(data);
      setType(type);
      switch (type) {
        case "Customer":
          setTitle("Sửa sổ khách hàng");
          api.get("/Customer/GetByID?id=" + id).then((res) => {
            setCustomer(res.data);
          });
          break;
        case "Supplier":
          setTitle("Sửa sổ đầu vào");
          break;
        case "Worker":
          setTitle("Sửa sổ thợ");
          break;

        default:
          break;
      }
      setVisible(true);
    },
  }));

  const payChange = (value, name) => {
    var pay = { ...payData };
    pay[name] = value;
    setPayData(pay);
  };

  const submit = () => {
    if (!payData.price) {
      message.error("Vui lòng nhập số tiền");
      return;
    }
    setLoading(true);
    api
      .post(`/${type}/UpdateTransaction`, payData)
      .then((res) => {
        setLoading(false);
        message.success("Lưu thành công");
        props.reload();
        setVisible(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Lưu thất bại");
      });
  };

  return (
    <Modal
      maskClosable={false}
      width={400}
      open={visible}
      title={title}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        submit();
      }}
      okText="Sửa"
      cancelText="Thoát"
      footer={[
        <Button
          loading={loading}
          type="primary"
          onClick={() => {
            submit();
          }}
        >
          Sửa
        </Button>,
      ]}
    >
      <div>
        <div style={{ fontWeight: "bold", marginBottom: 10 }}>Số tiền</div>
        <div style={{ marginBottom: 20 }}>
          <InputNumber
            inputMode="numeric"
            min={0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            step={1000}
            size="large"
            placeholder="Số tiền"
            style={{ width: "100%" }}
            value={payData.price}
            onChange={(e) => {
              payChange(e, "price");
            }}
          ></InputNumber>
        </div>
        {customer.is_wholesale ? (
          <div style={{ marginBottom: 20 }}>
            <Checkbox
              checked={payData.is_deposit}
              size="large"
              onChange={(e) => {
                payChange(e.target.checked, "is_deposit");
              }}
            >
              Tiền cọc
            </Checkbox>
          </div>
        ) : (
          <></>
        )}
        <div style={{ marginBottom: 20 }}>
          <Radio.Group
            name="radiogroup"
            value={payData.payment_method}
            size="large"
            onChange={(e) => {
              payChange(e.target.value, "payment_method");
            }}
          >
            <Radio value={"transfer"}>Chuyển khoản</Radio>
            <Radio value={"cash"}>Tiền mặt</Radio>
          </Radio.Group>
        </div>
      </div>
    </Modal>
  );
});
export default PopupEditPay;
