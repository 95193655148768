import React from "react";
import { Input, Select } from "antd";
import api from "api";
import { createSearchText } from "common/Text";

const AddressPicker = (props) => {
  const [lstProvince, setLstProvince] = React.useState([]);
  const [lstDistrict, setLstDistrict] = React.useState([]);
  const [lstWard, setLstWard] = React.useState([]);
  const [province, setProvince] = React.useState();
  const [district, setDistrict] = React.useState();
  const onChange = (value) => {
    props.onChange(value);
  };
  React.useEffect(() => {
    api.get("Address/GetProvince").then((res) => {
      setLstProvince(res.data);
    });
  }, []);
  React.useEffect(() => {
    if (props.value) {
      api.get("Address/GetAddressByCode?code=" + props.value).then((res) => {
        setProvince(res.data.province);
        setDistrict(res.data.district);
        api.get("Address/GetDistrict?province=" + res.data.province).then((res) => {
          setLstDistrict(res.data);
        });
        api.get(`Address/GetWard?province=${res.data.province}&district=${res.data.district}`).then((res) => {
          setLstWard(res.data);
        });
      });
    }
  }, [props.value]);
  const handleProvinceChange = (value) => {
    setProvince(value);
    api.get("Address/GetDistrict?province=" + value).then((res) => {
      setLstDistrict(res.data);
      setDistrict();
      handleWardChange(null);
    });
  };
  const handleDistrictChange = (value) => {
    setDistrict(value);
    api.get(`Address/GetWard?province=${province}&district=${value}`).then((res) => {
      setLstWard(res.data);
      handleWardChange(null);
    });
  };
  const handleWardChange = (value) => {
    onChange(value);
  };
  return (
    <div style={{ display: "flex", gap: 10 }}>
      <Select
        showSearch={true}
        options={lstProvince.map((x) => {
          return { label: x, value: x };
        })}
        placeholder={"Tỉnh"}
        value={province}
        onChange={handleProvinceChange}
        filterOption={(key, option) => {
          key = createSearchText(key);
          var label = createSearchText(option.label);
          if (label.includes(key)) return true;
          return false;
        }}
      ></Select>
      <Select
        showSearch={true}
        options={lstDistrict.map((x) => {
          return { label: x, value: x };
        })}
        placeholder={"Huyện"}
        value={district}
        onChange={handleDistrictChange}
        filterOption={(key, option) => {
          key = createSearchText(key);
          var label = createSearchText(option.label);
          if (label.includes(key)) return true;
          return false;
        }}
      ></Select>
      <Select
        showSearch={true}
        options={lstWard.map((x) => {
          return { label: x.ward, value: x.code };
        })}
        placeholder={"Xã"}
        value={props.value}
        onChange={handleWardChange}
        autoClearSearchValue={true}
        filterOption={(key, option) => {
          key = createSearchText(key);
          var label = createSearchText(option.label);
          if (label.includes(key)) return true;
          return false;
        }}
      ></Select>
    </div>
  );
};
export default AddressPicker;
