import React from "react";
import { Modal, Form, Input, message, Button, Checkbox, Select, InputNumber } from "antd";
import api from "api";
import AddressPicker from "./address_picker";

const JATConfigPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState();
  const form = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open() {
      setVisible(true);
      setTimeout(() => {
        api.get("ShopConfig/GetConfig").then((res) => {
          if (res.data) {
            form.current.setFieldsValue(res.data);
          } else {
            form.current.setFieldsValue({ jat_partsign: false, jat_is_insured: false });
          }
        });
      }, 500);
    },
  }));

  const onFinish = (values) => {
    setLoading(true);
    api
      .post("ShopConfig/SaveConfig", values)
      .then((res) => {
        message.success("Lưu thành công");
        setVisible(false);
        setLoading(false);
        close();
      })
      .catch((err) => {
        message.error("Lưu thất bại");
        setLoading(false);
      });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  return (
    <Modal
      maskClosable={false}
      width={600}
      open={visible}
      title={"Cấu hình shop"}
      onCancel={() => {
        close();
      }}
      onOk={() => {
        form.current.submit();
      }}
      okText="Sửa"
      cancelText="Thoát"
      footer={[
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            form.current.submit();
          }}
        >
          Lưu
        </Button>,
      ]}
    >
      <Form layout="vertical" ref={form} onFinish={onFinish}>
        <Form.Item hidden={true} name={"id"} />
        <Form.Item
          name="shop_name"
          label="Tên shop"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="shop_phone"
          label="SDT shop"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="shop_address_code"
          label="Địa chỉ shop"
        >
          <AddressPicker />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="shop_address"
        >
          <Input />
        </Form.Item>

        <div style={{ display: "flex", gap: 20 }}>
          <Form.Item
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
            name="jat_customer_id"
            label="Mã khách hàng j&t"
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            label="Phí ship"
            rules={[
              {
                required: true,
              },
            ]}
            name={"ship_fee"}
          >
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              step={1000}
              min={0}
            ></InputNumber>
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 20 }}>
          <Form.Item
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
            name="jat_special"
            label="Dịch vụ"
          >
            <Select
              options={[
                {
                  label: "Dịch vụ tiêu chuẩn",
                  value: "EXPRESS",
                },
                {
                  label: "Dịch vụ chuyển nhanh",
                  value: "FAST",
                },
                {
                  label: "Dịch vụ hỏa tốc",
                  value: "SUPER",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
            name="jat_service_type"
            label="Gửi hàng"
          >
            <Select
              options={[
                {
                  label: "Gửi tại bưu cục",
                  value: 6,
                },
                {
                  label: "Shiper thu tận nơi",
                  value: 1,
                },
              ]}
            />
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 20 }}>
          <Form.Item
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
            name="jat_is_insured"
            label="Có bảo hiểm"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
            name="jat_partsign"
            label="Giao hàng 1 phần"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            name={"weight"}
            label="Trọng lượng hàng hóa"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber min={0} suffix="KG" />
          </Form.Item>
        </div>
        {/* <div style={{ display: "flex", gap: 20 }}>
          <Form.Item
            style={{ flex: 1 }}
            name={"height"}
            label="Chiều cao hàng hóa"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            name={"width"}
            label="Chiều rộng hàng hóa"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            name={"length"}
            label="Ciều dài hàng hóa"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </div> */}
      </Form>
    </Modal>
  );
});
export default JATConfigPopup;
