import React from "react";
import { Input, Radio, Modal, Card, Button, InputNumber, Select, message } from "antd";
import api from "api";
import { useParams } from "react-router-dom";
import { createSearchText } from "common/Text";

const PopupEditWorkerTransaction = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState({});
  const [lstFabric, setLstFabric] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { id } = useParams();

  React.useEffect(() => {
    api.get("Fabric/GetList").then((res) => {
      setLstFabric(res.data);
    });
  }, []);

  React.useImperativeHandle(ref, () => ({
    open(data) {
      console.log(data);
      if (data.price) data.price = Math.abs(data.price);

      setData(data);

      setVisible(true);
    },
  }));

  const dataChange = (value, name) => {
    var tran = { ...data };
    if (name == "fabric_id") {
      tran.fabric_name = value.label;
      tran.fabric_id = value.value;
    } else tran[name] = value;
    setData(tran);
  };

  const submit = () => {
    console.log(data);
    if (!data.quantity) {
      message.error("Vui lòng nhập số lượng");
      return;
    }
    if (!data.price && data.type.includes("product")) {
      message.error("Vui lòng nhập giá");
      return;
    }
    setLoading(true);
    api
      .post(`/worker/UpdateTransaction`, data)
      .then((res) => {
        setLoading(false);
        message.success("Lưu thành công");
        props.reload();
        setVisible(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Lưu thất bại");
      });
  };

  return (
    <Modal
      maskClosable={false}
      width={400}
      open={visible}
      title={"Sửa sổ thợ"}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        submit();
      }}
      okText="Sửa"
      cancelText="Thoát"
      footer={[
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            submit();
          }}
        >
          Sửa
        </Button>,
      ]}
    >
      <div>
        <div style={{ marginBottom: 20 }}>
          <Select
            placeholder="Chọn loại vải"
            size="large"
            style={{
              width: "100%",
            }}
            options={lstFabric.map((x) => {
              return {
                label: x.name,
                value: x.id,
              };
            })}
            showSearch={true}
            autoClearSearchValue={true}
            filterOption={(key, option) => {
              key = createSearchText(key);
              var label = createSearchText(option.name);
              if (label.includes(key)) return true;
              return false;
            }}
            virtual={false}
            value={data.fabric_id}
            onChange={(e, f) => {
              dataChange(f, "fabric_id");
            }}
          />
        </div>
        <div style={{ marginBottom: 20 }}>
          <InputNumber
            inputMode="numeric"
            min={0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            step={1000}
            size="large"
            placeholder="Số lượng"
            style={{ marginRight: 10, marginBottom: 10 }}
            value={data.quantity}
            onChange={(e) => {
              dataChange(e, "quantity");
            }}
          ></InputNumber>
          {data.type?.includes("product") ? (
            <InputNumber
              inputMode="numeric"
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              step={1000}
              size="large"
              placeholder="Giá"
              style={{ width: 150 }}
              value={data.price}
              onChange={(e) => {
                dataChange(e, "price");
              }}
            ></InputNumber>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Modal>
  );
});
export default PopupEditWorkerTransaction;
