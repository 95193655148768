import * as React from "react";
import api from "api";
import { Row, Input, Col, Form, message, DatePicker, Select, Modal, Upload, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Option } = Select;
const { TextArea } = Input;

const UserPopup = React.forwardRef((props, ref) => {
  const [data, setData] = React.useState({});
  const [lstRole, setLstRole] = React.useState();
  const [visible, setVisible] = React.useState();
  const form = React.useRef();

  React.useEffect(() => {
    api.get("User/GetListRole").then((res) => {
      setLstRole(res.data);
    });
  }, []);

  /**
   * set lại state flter khi có data thay đổi
   *
   * @param {Object} values - thông tin user .
   */
  const saveUser = (values) => {
    api
      .post("User/SaveUser", values)
      .then((res) => {
        message.success("Thành công");
        if (props.reload) props.reload();
        setVisible(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  React.useImperativeHandle(ref, () => ({
    /**
     * Mở popup
     *
     * @param {Object} user - thông tin user để gán vào form .
     */
    open(user) {
      setVisible(true);
      setTimeout(() => {
        if (user) {
          api.get("/user/GetByUsername?username=" + user.user_name).then((res) => {
            res.data.birthday = res.data.birthday ? dayjs(res.data.birthday) : null;
            setData(res.data);
            form.current.setFieldsValue(res.data);
          });
        } else {
          setData({});
          form.current.resetFields();
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={`Chỉnh sửa ${props.type == "staff" ? "nhân viên" : "học sinh"}`}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
        form.current.resetFields();
      }}
    >
      <Form ref={form} onFinish={saveUser} name="validateOnly" layout="vertical">
        <Form.Item name="id"></Form.Item>

        <Form.Item label="Tên" name="name" rules={[{ required: true, message: "Nhập tên!" }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Tên đăng nhập" name="user_name" rules={[{ required: true, message: "Nhập tên đăng nhập!" }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Mật khẩu" name="password_new" rules={data.id != null ? [] : [{ required: true, message: "Vui lòng nhập mật khẩu!" }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item label="Quyền" name="role_id" rules={[{ required: true, message: "Vui lòng chọn quyền!" }]}>
          <Select options={lstRole} fieldNames={{ label: "name", value: "id" }} virtual={false}></Select>
        </Form.Item>

        <Form.Item label="Chức danh" name="position">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default UserPopup;
