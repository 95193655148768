import React from "react";
import { Modal, message, Input, InputNumber, Button } from "antd";
import api from "api";
import { Timeline } from "antd";
import { formatCurrency, formatLongDate } from "common/Format2";

const PopupShowPayTransaction = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useImperativeHandle(ref, () => ({
    open(type, id) {
      api.get(`${type}/GetPayTransaction?id=` + id).then((res) => {
        setData(res.data);
      });
      setVisible(true);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      onCancel={() => {
        setVisible(false);
      }}
      width={600}
      open={visible}
      title={"Liệt kê thanh toán"}
      footer={
        <Button
          type="primary"
          onClick={() => {
            setVisible(false);
          }}
        >
          Thoát
        </Button>
      }
    >
      <Timeline
        items={data.map((x) => {
          return {
            children: `Ngày: ${formatLongDate(x.create_date)} - Hình thức: ${
              x.payment_method == "cash" ? "tiền mặt" : "chuyển khoản"
            } - Số tiền: ${formatCurrency(x.price)}`,
          };
        })}
      />
    </Modal>
  );
});
export default PopupShowPayTransaction;
