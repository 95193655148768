import * as React from "react";
import api from "api";
import { Popconfirm, Button, Space, Card, message, Input, Row, Col } from "antd";
import UserPopup from "./user_popup";
import { EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, RotateRightOutlined } from "@ant-design/icons";
import AntTable from "component/common/AntTable";
import { useNavigate } from "react-router-dom";
import { Header } from "Client";

const User = (props) => {
  //infor để search user
  const [info, setInfo] = React.useState();
  const [lstRole, setLstRole] = React.useState();
  const userPopup = React.useRef();
  const tbluser = React.useRef();
  const navigate = useNavigate();
  const { userLogin } = React.useContext(Header);

  React.useEffect(() => {
    if (userLogin.role_id != 1) navigate("/");
  }, []);

  /**
   * Lấy danh sách user
   */
  const getlstuser = () => {
    tbluser.current.reload();
  };

  /**
   * Xóa  user
   *
   * @param  {int} id - id user .
   */
  const deleteUser = (id) => {
    api
      .get("User/DeleteUser?username=" + id)
      .then((res) => {
        message.success(res.message);
        getlstuser();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },

    {
      title: "Tên tài khoản",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Tên nhân viên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quyền",
      dataIndex: "role_name",
      key: "rile_name",
    },
    {
      title: "Chức vụ",
      dataIndex: "position",
      key: "position",
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              userPopup.current.open();
            }}
          >
            <PlusOutlined />
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                userPopup.current.open(record);
              }}
            >
              <EditOutlined />
            </Button>

            {record.user_name != "admin" ? (
              <Popconfirm
                title="Bạn có muốn xóa tài khoản này không?"
                placement="topRight"
                onConfirm={() => {
                  deleteUser(record.user_name);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            ) : (
              <></>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row style={{ width: "100%" }}>
          <Col span={8}>
            <div>
              <div>
                <b>Thông tin</b>
              </div>
              <div>
                <Input
                  onChange={(e) => {
                    setInfo(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <Button
            type="primary"
            onClick={() => {
              getlstuser();
            }}
          >
            Tìm kiếm
          </Button>
        </div>
      </Card>

      <AntTable columns={col} ajax="User/Listuser" ref={tbluser} customSearch={{ Info: info }}></AntTable>
      <UserPopup ref={userPopup} reload={getlstuser} type={"staff"}></UserPopup>
    </>
  );
};

export default User;
