import React from "react";
import { Input, DatePicker, Button, message, Checkbox, Switch } from "antd";
import api from "api";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const { TextArea } = Input;

const CustomerInfo = (props) => {
  const [data, setData] = React.useState({ is_wholesale: false });
  const [loading, setLoading] = React.useState(false);
  const [mayDo, setMayDo] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (id) {
      api.get("/Customer/GetByID?id=" + id).then((res) => {
        res.data.birthday = dayjs(res.data.birthday);
        setData(res.data);
      });
    }
  }, []);

  const dataChange = (value, name) => {
    var cus = { ...data };
    cus[name] = value;
    setData(cus);
  };

  const saveCustomer = () => {
    if (!data.name || data.name == "") {
      message.error("Vui lòng nhập tên khách hàng");
      return;
    }
    setLoading(true);
    var dataPost = { ...data };
    if (data.birthday != null && dayjs.isDayjs(data.birthday)) dataPost.birthday = dayjs(data.birthday).format();

    api
      .post("/Customer/Save", dataPost)
      .then((res) => {
        message.success("Lưu thành công");
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Lưu thất bại");
      });
  };

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <b>Tên</b>
          </div>
          <div>
            <Input
              size="large"
              value={data.name}
              onChange={(e) => {
                dataChange(e.target.value, "name");
              }}
            ></Input>
          </div>
        </div>
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <b>Mua sỉ</b>
          </div>
          <div>
            <Checkbox
              size="large"
              checked={data.is_wholesale}
              onChange={(e) => {
                dataChange(e.target.checked, "is_wholesale");
              }}
            ></Checkbox>
          </div>
        </div>
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <b>Ngày sinh</b>
          </div>
          <div>
            <DatePicker
              format={"DD/MM/YYYY"}
              value={data.birthday ?? null}
              size="large"
              onChange={(e) => {
                dataChange(e, "birthday");
              }}
            ></DatePicker>
          </div>
        </div>
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <b>Địa chỉ</b>
          </div>
          <div>
            <Input
              value={data.address}
              size="large"
              onChange={(e) => {
                dataChange(e.target.value, "address");
              }}
            ></Input>
          </div>
        </div>
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <b>Gửi chành xe</b>
          </div>
          <div>
            <Input
              value={data.gui_chanh_xe}
              size="large"
              onChange={(e) => {
                dataChange(e.target.value, "gui_chanh_xe");
              }}
            ></Input>
          </div>
        </div>
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <b>Note</b>
          </div>
          <div>
            <TextArea
              value={data.note}
              size="large"
              onChange={(e) => {
                dataChange(e.target.value, "note");
              }}
            ></TextArea>
          </div>
        </div>
        <div>
          <Switch
            onChange={(check) => {
              setMayDo(check);
            }}
            checked={mayDo}
          ></Switch>
          &nbsp; &nbsp;
          <b>May đo</b>
        </div>
        {mayDo ? (
          <>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Dài áo</b>
              </div>
              <div>
                <Input
                  value={data.dai_ao}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "dai_ao");
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Ngực</b>
              </div>
              <div>
                <Input
                  value={data.nguc}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "nguc");
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Eo</b>
              </div>
              <div>
                <Input
                  value={data.eo}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "eo");
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Mông</b>
              </div>
              <div>
                <Input
                  value={data.mong}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "mong");
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Dài tay</b>
              </div>
              <div>
                <Input
                  value={data.dai_tay}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "dai_tay");
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Nách</b>
              </div>
              <div>
                <Input
                  value={data.nach}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "nach");
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Bắp tay</b>
              </div>
              <div>
                <Input
                  value={data.bap_tay}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "bap_tay");
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Vòng cổ</b>
              </div>
              <div>
                <Input
                  value={data.vong_co}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "vong_co");
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Hạ eo</b>
              </div>
              <div>
                <Input
                  value={data.ha_eo}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "ha_eo");
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Dài quần</b>
              </div>
              <div>
                <Input
                  value={data.dai_quan}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "dai_quan");
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <b>Eo quần</b>
              </div>
              <div>
                <Input
                  value={data.eo_quan}
                  size="large"
                  onChange={(e) => {
                    dataChange(e.target.value, "eo_quan");
                  }}
                ></Input>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button size="large" type="primary" onClick={saveCustomer} style={{ width: "47%" }} loading={loading}>
          Lưu
        </Button>
        <Button
          style={{ width: "47%" }}
          size="large"
          type="primary"
          onClick={() => {
            if (id) navigate("/customer_transaction/" + id);
            else navigate("/");
          }}
        >
          Trở lại
        </Button>
      </div>
    </div>
  );
};
export default CustomerInfo;
