import React from "react";
import { Select, Card, Button, InputNumber, Radio, DatePicker, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import api from "api";
import PopupInsert from "component/home/popup_insert";
import { createSearchText } from "common/Text";
import { formatCurrency } from "common/Format2";
import PopupShowPayTransaction from "component/home/popup_show_pay_transaction";
import PopupShowFabricDeliveryTransaction from "component/home/popup_show_fabric_delivery_transaction";
import { Header } from "Client";
import dayjs from "dayjs";

const Worker = () => {
  const [lstFabric, setLstFabric] = React.useState([]);
  const [fabricDeliveryData, setFabricDeliveryData] = React.useState({ type: "fabric_delivery" });
  const [productDeliveryData, setProductDeliveryData] = React.useState({ type: "product_delivery" });
  const [returnProductData, setReturnProductData] = React.useState({ type: "return_product" });
  const [returnFabricData, setReturnFabricData] = React.useState({ type: "return_fabric" });
  const [payData, setPayData] = React.useState({ type: "pay", payment_method: "cash" });
  const [lastTransaction, setLasTransaction] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [date, setDate] = React.useState(dayjs());

  const { userLogin } = React.useContext(Header);
  const navigate = useNavigate();
  const popupInsert = React.useRef();
  const { id } = useParams();
  const popupShowPayTransaction = React.useRef();
  const popupShowFabricDeliveryTransaction = React.useRef();

  React.useEffect(() => {
    getListFabric();
    getLastTransaction();
  }, []);

  const getListFabric = () => {
    api.get("/Fabric/GetList").then((res) => {
      setLstFabric(res.data);
    });
  };
  const getLastTransaction = () => {
    api.get("/Worker/GetLastTransaction?workerID=" + id).then((res) => {
      setLasTransaction(res.data);
    });
  };
  const saveTransaction = async (data) => {
    if (data.type != "pay" && data.fabric_id == null) {
      message.error("Vui lòng chọn loại vải");
      return false;
    }
    if (data.type != "pay" && data.quantity == null) {
      message.error("Vui lòng nhập số lượng");
      return false;
    }
    if (data.type != "fabric_delivery" && data.type != "return_fabric" && data.price == null) {
      message.error("Vui lòng nhập giá");
      return false;
    }
    if (data == "fabric_delivery" && data == "return_fabric") {
      data.price = null;
    }
    setLoading(true);
    var create_date = dayjs();
    create_date.date(date.date());
    create_date.month(date.month());
    create_date.year(date.year());
    data.create_date = create_date.format();
    var res = await api.post("/Worker/AddTranssactions", { workerID: id, transaction: data });
    if (res.code == 1) {
      message.success("Thành công");
      setLoading(false);
      getLastTransaction();
      return true;
    } else {
      message.error("Thất bại");
      setLoading(false);
      return false;
    }
  };

  const dataChange = (tran, value, name, price) => {
    var data = { ...tran };
    data[name] = value;

    return data;
  };

  return (
    <div>
      <div>
        <Card style={{ marginBottom: 20 }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{ width: "48%" }}
              size="large"
              type="primary"
              onClick={() => {
                navigate("/worker_history/" + id);
              }}
            >
              Xem sổ
            </Button>

            <Button
              style={{ width: "48%" }}
              size="large"
              type="primary"
              onClick={() => {
                popupInsert.current.open("Fabric");
              }}
            >
              Thêm loại vải
            </Button>
          </div>
        </Card>
        <Card style={{ marginBottom: 20 }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <DatePicker
              size="large"
              value={date}
              format={"DD-MM-YYYY"}
              onChange={(e) => {
                setDate(e);
              }}
            ></DatePicker>
          </div>
        </Card>
        <Card style={{ marginBottom: 20 }} title="Giao vải">
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
            <div style={{ width: "47%", display: "flex" }}>
              <div style={{ fontWeight: "bold", marginRight: 5 }}>Loại vải</div>
              <div style={{ flex: 1 }}>
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  options={lstFabric}
                  fieldNames={{ label: "name", value: "id" }}
                  showSearch={true}
                  autoClearSearchValue={true}
                  filterOption={(key, option) => {
                    key = createSearchText(key);
                    var label = createSearchText(option.name);
                    if (label.includes(key)) return true;
                    return false;
                  }}
                  virtual={false}
                  value={fabricDeliveryData.fabric_id}
                  onChange={(e, f) => {
                    var data = dataChange(fabricDeliveryData, e, "fabric_id", f.price);
                    setFabricDeliveryData(data);
                  }}
                />
              </div>
            </div>
            <div style={{ width: "47%", display: "flex" }}>
              <div style={{ fontWeight: "bold", marginRight: 5 }}>Số lượng</div>
              <div style={{ flex: 1 }}>
                <InputNumber
                  inputMode="numeric"
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  step={1000}
                  size="large"
                  style={{ width: "100%" }}
                  value={fabricDeliveryData.quantity}
                  onChange={(e) => {
                    var data = dataChange(fabricDeliveryData, e, "quantity");
                    setFabricDeliveryData(data);
                  }}
                ></InputNumber>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                var data = saveTransaction(fabricDeliveryData);
                if (data) setFabricDeliveryData({ type: "fabric_delivery" });
              }}
              loading={loading}
            >
              Xong
            </Button>
            {userLogin.role_id == 1 ? (
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  popupShowFabricDeliveryTransaction.current.open(id);
                }}
              >
                Liệt kê
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Card>
        <Card style={{ marginBottom: 20 }} title="Giao hàng">
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
            <div style={{ width: "47%", display: "flex" }}>
              <div style={{ fontWeight: "bold", marginRight: 5 }}>Loại vải</div>
              <div style={{ flex: 1 }}>
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  options={lstFabric}
                  fieldNames={{ label: "name", value: "id" }}
                  showSearch={true}
                  autoClearSearchValue={true}
                  filterOption={(key, option) => {
                    key = createSearchText(key);
                    var label = createSearchText(option.name);
                    if (label.includes(key)) return true;
                    return false;
                  }}
                  virtual={false}
                  value={productDeliveryData.fabric_id}
                  onChange={(e, f) => {
                    var data = dataChange(productDeliveryData, e, "fabric_id", f.price);
                    setProductDeliveryData(data);
                  }}
                />
              </div>
            </div>
            <div style={{ width: "47%" }}>
              <div style={{ display: "flex", marginBottom: 20 }}>
                <div style={{ fontWeight: "bold", marginRight: 5 }}>Số lượng</div>
                <div style={{ flex: 1 }}>
                  <InputNumber
                    inputMode="numeric"
                    min={0}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    step={1000}
                    size="large"
                    style={{ width: "100%" }}
                    value={productDeliveryData.quantity}
                    onChange={(e) => {
                      var data = dataChange(productDeliveryData, e, "quantity");
                      setProductDeliveryData(data);
                    }}
                  ></InputNumber>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ fontWeight: "bold", marginRight: 5, width: 60 }}>Giá</div>
                <div style={{ flex: 1 }}>
                  <InputNumber
                    inputMode="numeric"
                    min={0}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    step={1000}
                    size="large"
                    style={{ width: "100%" }}
                    value={productDeliveryData.price}
                    onChange={(e) => {
                      var data = dataChange(productDeliveryData, e, "price");
                      setProductDeliveryData(data);
                    }}
                  ></InputNumber>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                var data = saveTransaction(productDeliveryData);
                if (data) setProductDeliveryData({ type: "product_delivery" });
              }}
              loading={loading}
            >
              Xong
            </Button>
          </div>
        </Card>
        <Card style={{ marginBottom: 20 }} title="Thanh toán">
          <div style={{ display: "flex", marginBottom: 20 }}>
            <div style={{ fontWeight: "bold", marginRight: 5 }}>Số tiền</div>
            <InputNumber
              inputMode="numeric"
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              step={1000}
              size="large"
              style={{ flex: 1 }}
              value={payData.price}
              onChange={(e) => {
                var data = dataChange(payData, e, "price");
                setPayData(data);
              }}
            ></InputNumber>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Radio.Group
              name="radiogroup"
              size="large"
              value={payData.payment_method}
              onChange={(e) => {
                var data = dataChange(payData, e.target.value, "payment_method");
                setPayData(data);
              }}
            >
              <Radio value={"tranfer"}>Chuyển khoản</Radio>
              <Radio value={"cash"}>Tiền mặt</Radio>
            </Radio.Group>
          </div>
          {lastTransaction ? (
            <div style={{ display: "flex", marginBottom: 20 }}>
              <div style={{ fontWeight: "bold", marginRight: 5 }}>Số tiền còn lại</div> &nbsp; <div>{formatCurrency(lastTransaction.profit ?? 0)}</div>
            </div>
          ) : (
            <></>
          )}

          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              type="primary"
              onClick={() => {
                var data = saveTransaction(payData);
                if (data) setPayData({ type: "product_delivery" });
              }}
              loading={loading}
            >
              Xong
            </Button>
            {userLogin.role_id == 1 ? (
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  popupShowPayTransaction.current.open("Worker", id);
                }}
              >
                Liệt kê
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Card>
        <Card style={{ marginBottom: 20 }} title="Đề phô">
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
            <div style={{ width: "47%", display: "flex" }}>
              <div style={{ fontWeight: "bold", marginRight: 5 }}>Loại vải</div>
              <div style={{ flex: 1 }}>
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  options={lstFabric}
                  fieldNames={{ label: "name", value: "id" }}
                  showSearch={true}
                  autoClearSearchValue={true}
                  filterOption={(key, option) => {
                    key = createSearchText(key);
                    var label = createSearchText(option.name);
                    if (label.includes(key)) return true;
                    return false;
                  }}
                  virtual={false}
                  value={returnProductData.fabric_id}
                  onChange={(e, f) => {
                    var data = dataChange(returnProductData, e, "fabric_id", f.price);
                    setReturnProductData(data);
                  }}
                />
              </div>
            </div>
            <div style={{ width: "47%" }}>
              <div style={{ display: "flex", marginBottom: 20 }}>
                <div style={{ fontWeight: "bold", marginRight: 5 }}>Số lượng</div>
                <div style={{ flex: 1 }}>
                  <InputNumber
                    inputMode="numeric"
                    min={0}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    step={1000}
                    size="large"
                    style={{ width: "100%" }}
                    value={returnProductData.quantity}
                    onChange={(e) => {
                      var data = dataChange(returnProductData, e, "quantity");
                      setReturnProductData(data);
                    }}
                  ></InputNumber>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ fontWeight: "bold", marginRight: 5, width: 60 }}>Giá</div>
                <div style={{ flex: 1 }}>
                  <InputNumber
                    inputMode="numeric"
                    min={0}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    step={1000}
                    size="large"
                    style={{ width: "100%" }}
                    value={returnProductData.price}
                    onChange={(e) => {
                      var data = dataChange(returnProductData, e, "price");
                      setReturnProductData(data);
                    }}
                  ></InputNumber>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="large"
              type="primary"
              loading={loading}
              onClick={() => {
                var data = saveTransaction(returnProductData);
                if (data) setReturnProductData({ type: "return_product" });
              }}
            >
              Xong
            </Button>
          </div>
        </Card>
        <Card style={{ marginBottom: 20 }} title="Thu vải">
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
            <div style={{ width: "47%", display: "flex" }}>
              <div style={{ fontWeight: "bold", marginRight: 5 }}>Loại vải</div>
              <div style={{ flex: 1 }}>
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  options={lstFabric}
                  fieldNames={{ label: "name", value: "id" }}
                  showSearch={true}
                  autoClearSearchValue={true}
                  filterOption={(key, option) => {
                    key = createSearchText(key);
                    var label = createSearchText(option.name);
                    if (label.includes(key)) return true;
                    return false;
                  }}
                  virtual={false}
                  value={returnFabricData.fabric_id}
                  onChange={(e, f) => {
                    var data = dataChange(returnFabricData, e, "fabric_id", f.price);
                    setReturnFabricData(data);
                  }}
                />
              </div>
            </div>
            <div style={{ width: "47%", display: "flex" }}>
              <div style={{ fontWeight: "bold", marginRight: 5 }}>Số lượng</div>
              <div style={{ flex: 1 }}>
                <InputNumber
                  inputMode="numeric"
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  step={1000}
                  size="large"
                  style={{ width: "100%" }}
                  value={returnFabricData.quantity}
                  onChange={(e) => {
                    var data = dataChange(returnFabricData, e, "quantity");
                    setReturnFabricData(data);
                  }}
                ></InputNumber>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="large"
              type="primary"
              loading={loading}
              onClick={() => {
                var data = saveTransaction(returnFabricData);
                if (data) setReturnFabricData({ type: "return_fabric" });
              }}
            >
              Xong
            </Button>
          </div>
        </Card>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Button
          type="primary"
          size="large"
          style={{ width: "47%" }}
          onClick={() => {
            navigate("/");
          }}
        >
          Trở lại
        </Button>
      </div>
      <PopupInsert ref={popupInsert} getListFabric={getListFabric} />
      <PopupShowPayTransaction ref={popupShowPayTransaction} />
      <PopupShowFabricDeliveryTransaction ref={popupShowFabricDeliveryTransaction} />
    </div>
  );
};
export default Worker;
