import { Table, Card, Checkbox, Button, Modal, message, Form, Input, Space, InputNumber, Divider } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import dayjs from "dayjs";
import api from "api";
import { Routes, Route, useParams } from "react-router-dom";
import { formatLongDate, formatCurrency } from "common/Format2";
import { useNavigate } from "react-router-dom";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
const OrderDetail = React.forwardRef((props, ref) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  React.useImperativeHandle(ref, () => ({
    open(id) {
      setIsModalOpen(true);
      if (id) {
        api.get("LiveOrder/GetOrderInfo?id=" + id).then((res) => {
          var data = {
            id: res.data.order.id,
            customer_name: res.data.cus ? res.data.cus.name : res.data.order.customer_name,
            customer_phone: res.data.cus?.phone,
            details: res.data.details,
          };
          form.setFieldsValue(data);
        });
      } else {
        form.setFieldsValue({ session_id: props.sessionID, is_freeship: false });
      }
    },
  }));

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    if (values.id && values.id != 0)
      api.post("LiveOrder/SaveEditOrder", values).then((res) => {
        message.success("Lưu thành công");
        handleCancel();
        props.getData();
      });
    else
      api.post("LiveOrder/SaveNewOrder", values).then((res) => {
        message.success("Lưu thành công");
        handleCancel();
        props.getData();
      });
  };
  return (
    <Modal
      title="Chỉnh sửa đơn hàng"
      open={isModalOpen}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Hủy
        </Button>,
        <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={handleOk}>
          Lưu
        </Button>,
      ]}
      maskClosable={false}
      closeIcon={false}
    >
      <Form
        layout={{
          labelCol: {
            span: 8,
          },
          wrapperCol: {
            span: 16,
          },
        }}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item hidden={true} name={"id"} />
        <Form.Item hidden={true} name={"session_id"} />
        <Form.Item
          name="customer_name"
          label="Tên khách"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input style={{ textAlign: "right" }} />
        </Form.Item>
        <Form.Item name="customer_phone" label="Số điện thoại">
          <Input style={{ textAlign: "right" }} />
        </Form.Item>
        <Form.Item name="is_freeship" valuePropName="checked">
          <Checkbox>Freeship</Checkbox>
        </Form.Item>
        <Form.Item name={"weight"} label="Cân nặng">
          <InputNumber min={0} suffix="KG" />
        </Form.Item>

        <Divider>Sản Phẩm</Divider>
        <Form.List name={"details"}>
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space style={{ marginBottom: 5 }}>
                    <Form.Item
                      name={[name, "product"]}
                      rules={[
                        {
                          required: true,
                          message: "Nhập mã sản phẩm",
                        },
                        {
                          validator: (rule, value) => {
                            return new Promise((resolve, error) => {
                              var data = form.getFieldsValue();
                              var count = data.details.filter((x) => x.product == value).length;
                              if (count == 1) {
                                resolve();
                              } else {
                                error("Trùng mã sản phẩm");
                              }
                            });
                          },
                          message: "Trùng mã sản phẩm",
                        },
                      ]}
                    >
                      <Input
                        disabled={form.getFieldValue("details")[index] ? true : false}
                        placeholder="Mã SP"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[name, "quantity"]}
                      rules={[
                        {
                          required: true,
                          message: "Nhập số lượng",
                        },
                      ]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                      name={[name, "price"]}
                      rules={[
                        {
                          required: true,
                          message: "Nhập giá",
                        },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                    </Form.Item>
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} style={{ marginTop: 15 }} block icon={<PlusOutlined />}>
                    Thêm sản phẩm
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
});
export default OrderDetail;
