import React from "react";
import { Button, Table, Card, Space, Tag, Row, Col, message, Popconfirm } from "antd";
import { useParams } from "react-router-dom";
import { EditOutlined, RestOutlined } from "@ant-design/icons";
import api from "api";
import dayjs from "dayjs";
import { formatCurrency, formatShortDate } from "common/Format2";
import PopupEditPay from "component/home/popup_edit_pay";
import PopupEditWorkerTransaction from "component/home/popup_edit_worker_transaction";

const WorkerHistoryPrint = React.forwardRef((props, ref) => {
  const [loading, setLoading] = React.useState();
  const popupEditPay = React.useRef();
  const popupEditWorkerTransaction = React.useRef();
  const cols = [
    {
      title: "Thời gian",
      dataIndex: "create_date",
      render: (text, record, index) => {
        return dayjs(record.create_date).format("HH:mm");
      },
    },
    {
      title: "Tên vải",
      dataIndex: "type",
      render: (text, record, index) => {
        switch (text) {
          case "pay":
            return "Thanh toán";
          case "product_delivery":
            return record.fabric_name;

          default:
            return record.fabric_name;
        }
      },
    },

    {
      title: "SL",
      dataIndex: "quantity",
    },
    {
      title: "Giá",
      dataIndex: "price",
      render: (test, record, index) => {
        if (record.price >= 0) return <div>{formatCurrency(Math.abs(record.price))}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(Math.abs(record.price))}</div>;
      },
    },
    {
      title: "Tổng",
      dataIndex: "total",
      render: (test, record, index) => {
        if (record.total >= 0) return <div>{formatCurrency(Math.abs(record.total))}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(Math.abs(record.total))}</div>;
      },
    },
  ];

  const colButtons = [
    {
      title: "Thời gian",
      dataIndex: "create_date",
      render: (text, record, index) => {
        return dayjs(record.create_date).format("HH:mm");
      },
    },
    {
      title: "Tên vải",
      dataIndex: "type",
      render: (text, record, index) => {
        switch (text) {
          case "pay":
            return "Thanh toán";
          case "product_delivery":
            return record.fabric_name;

          default:
            return record.fabric_name;
        }
      },
    },

    {
      title: "SL",
      dataIndex: "quantity",
    },
    {
      title: "Giá",
      dataIndex: "price",
      render: (test, record, index) => {
        if (record.price >= 0) return <div>{formatCurrency(Math.abs(record.price))}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(Math.abs(record.price))}</div>;
      },
    },
    {
      title: "Tổng",
      dataIndex: "total",
      render: (test, record, index) => {
        if (record.total >= 0) return <div>{formatCurrency(Math.abs(record.total))}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(Math.abs(record.total))}</div>;
      },
    },
    {
      render: (text, record, index) => {
        return (
          <Space>
            <Button
              loading={loading}
              type="primary"
              onClick={() => {
                popupEditWorkerTransaction.current.open(record);
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              loading={loading}
              title="Xóa sổ"
              description="Bạn có muôn xóa dòng này không?"
              onConfirm={() => {
                deleteTransaction(record.id, record.worker_id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={loading}>
                <RestOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const col2s = [
    {
      title: "Thời gian",
      dataIndex: "create_date",
      render: (text, record, index) => {
        return dayjs(record.create_date).format("HH:mm");
      },
    },
    {
      title: "PTTT",
      dataIndex: "payment_method",
      render: (text, record, index) => {
        if (text == "cash") return "Tiền mặt";
        else return "Chuyển khoản";
      },
    },

    {
      title: "Giá",
      dataIndex: "price",
      render: (test, record, index) => {
        if (record.price >= 0) return <div>{formatCurrency(Math.abs(record.price))}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(Math.abs(record.price))}</div>;
      },
    },
  ];

  const col2Buttons = [
    {
      title: "Thời gian",
      dataIndex: "create_date",
      render: (text, record, index) => {
        return dayjs(record.create_date).format("HH:mm");
      },
    },
    {
      title: "PTTT",
      dataIndex: "payment_method",
      render: (text, record, index) => {
        if (text == "cash") return "Tiền mặt";
        else return "Chuyển khoản";
      },
    },

    {
      title: "Giá",
      dataIndex: "price",
      render: (test, record, index) => {
        if (record.price >= 0) return <div>{formatCurrency(Math.abs(record.price))}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(Math.abs(record.price))}</div>;
      },
    },

    {
      render: (text, record, index) => {
        return (
          <Space>
            <Button
              loading={loading}
              type="primary"
              onClick={() => {
                popupEditPay.current.open("Worker", record);
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              loading={loading}
              title="Xóa sổ"
              description="Bạn có muôn xóa dòng này không?"
              onConfirm={() => {
                deleteTransaction(record.id, record.worker_id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={loading}>
                <RestOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const col3s = [
    {
      title: "Thời gian",
      dataIndex: "create_date",
      render: (text, record, index) => {
        return dayjs(record.create_date).format("HH:mm");
      },
    },
    {
      title: "Tên vải",
      dataIndex: "type",
      render: (text, record, index) => {
        return record.fabric_name;
      },
    },

    {
      title: "SL",
      dataIndex: "quantity",
    },
  ];

  const col3Buttons = [
    {
      title: "Thời gian",
      dataIndex: "create_date",
      render: (text, record, index) => {
        return dayjs(record.create_date).format("HH:mm");
      },
    },
    {
      title: "Tên vải",
      dataIndex: "type",
      render: (text, record, index) => {
        return record.fabric_name;
      },
    },

    {
      title: "SL",
      dataIndex: "quantity",
    },
    {
      render: (text, record, index) => {
        return (
          <Space>
            <Button
              loading={loading}
              type="primary"
              onClick={() => {
                popupEditWorkerTransaction.current.open(record);
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              loading={loading}
              title="Xóa sổ"
              description="Bạn có muôn xóa dòng này không?"
              onConfirm={() => {
                deleteTransaction(record.id, record.worker_id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={loading}>
                <RestOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const deleteTransaction = (id, workerID) => {
    setLoading(true);
    api
      .get(`Worker/DeleteTransaction?workerID=${workerID}&id=${id}`)
      .then((res) => {
        setLoading(false);
        message.success("Xóa thành công");
        props.getData();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Xóa thất bại");
      });
  };

  return (
    <div ref={ref} style={!props.type ? { padding: 20 } : {}}>
      {props.type != "web" ? (
        <div
          style={{
            fontWeight: "bold",
            fontSize: 20,
            textAlign: "center",
            padding: 20,
            backgroundColor: "#fff",
          }}
        >
          {props.header}
        </div>
      ) : (
        <></>
      )}
      <div style={{ marginBottom: 20 }}>
        {props.listData.map((x) => {
          var debt = x.lst[x.lst.length - 1]?.profit ?? 0;
          var debtOld = (x.lst[0]?.profit ?? 0) - (x.lst[0]?.total ?? 0);
          var lstPay = x.lst.filter((x) => x.type == "pay");
          var lstProductDelivery = x.lst.filter((x) => x.type == "product_delivery");
          var lstProductReturn = x.lst.filter((x) => x.type == "return_product");
          // var lstFabricReturn = x.lst.filter((x) => x.type == "return_fabric").map((y) => y.quantity + " cái " + y.fabric_name);
          // var lstFabricReturnString = lstFabricReturn.join(", ");
          // var lstFabricDelivery = x.lst.filter((x) => x.type == "fabric_delivery").map((y) => y.quantity + " cái " + y.fabric_name);
          // var lstFabricDeliveryString = lstFabricDelivery.join(", ");
          var lstFabricReturn = x.lst.filter((x) => x.type == "return_fabric");
          var lstFabricDelivery = x.lst.filter((x) => x.type == "fabric_delivery");
          var totalProductDelivery = lstProductDelivery.reduce((tong, item) => {
            return tong + item.total;
          }, 0);
          var totalProductReturn = lstProductReturn.reduce((tong, item) => {
            return tong + item.total;
          }, 0);
          var totalPay = lstPay.reduce((tong, item) => {
            return tong + item.total;
          }, 0);

          return (
            <>
              <div style={{ marginBottom: 20 }}>
                {x.lst.length > 0 ? (
                  <div>
                    <div style={{ fontWeight: "bold", marginBottom: 10, color: "red", fontSize: 20 }}>{dayjs(x.date).format("DD-MM-YYYY")}</div>
                    <div>
                      {lstProductDelivery.length > 0 ? (
                        <div>
                          <div style={{ fontWeight: "bold", textAlign: "center", marginBottom: 10 }}>Giao hàng</div>
                          <Table
                            columns={props.type != "web" ? cols : colButtons}
                            dataSource={lstProductDelivery}
                            bordered
                            pagination={false}
                            footer={() => {
                              return (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                  <div>Tổng: &nbsp; {formatCurrency(totalProductDelivery)}</div>
                                </div>
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {lstProductReturn.length > 0 ? (
                        <div>
                          <div style={{ fontWeight: "bold", textAlign: "center", marginBottom: 10 }}>Đề phô</div>
                          <Table
                            columns={props.type != "web" ? cols : colButtons}
                            dataSource={lstProductReturn}
                            bordered
                            pagination={false}
                            footer={() => {
                              return (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                  <div>Tổng: &nbsp; {formatCurrency(totalProductReturn)}</div>
                                </div>
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div>
                        <div style={{ fontWeight: "bold", textAlign: "center", marginBottom: 10 }}>Thanh toán</div>

                        {lstPay.length > 0 ? (
                          <Table
                            columns={props.type != "web" ? col2s : col2Buttons}
                            dataSource={lstPay}
                            bordered
                            pagination={false}
                            footer={() => {
                              return (
                                <>
                                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <div style={{ fontWeight: "bold" }}>
                                      Nợ cũ: &nbsp; <span style={debtOld < 0 ? { color: "red" } : {}}>{formatCurrency(debtOld)}</span>
                                    </div>
                                  </div>
                                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <div style={{ fontWeight: "bold" }}>
                                      Tổng nợ: &nbsp; <span style={debt < 0 ? { color: "red" } : {}}>{formatCurrency(debt)}</span>
                                    </div>
                                  </div>
                                </>
                              );
                            }}
                          />
                        ) : (
                          <Card>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                              <div style={{ fontWeight: "bold" }}>
                                Nợ cũ: &nbsp; <span style={debtOld < 0 ? { color: "red" } : {}}>{formatCurrency(debtOld)}</span>
                              </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                              <div style={{ fontWeight: "bold" }}>
                                Tổng nợ: &nbsp; <span style={debt < 0 ? { color: "red" } : {}}>{formatCurrency(debt)}</span>
                              </div>
                            </div>
                          </Card>
                        )}
                      </div>
                    </div>
                    {lstFabricDelivery.length > 0 ? (
                      <div>
                        <div style={{ fontWeight: "bold", textAlign: "center", marginBottom: 10 }}>Giao vải</div>
                        <Table columns={props.type != "web" ? col3s : col3Buttons} dataSource={lstFabricDelivery} bordered pagination={false} />
                      </div>
                    ) : (
                      <></>
                    )}
                    {lstFabricReturn.length > 0 ? (
                      <div>
                        <div style={{ fontWeight: "bold", textAlign: "center", marginBottom: 10 }}>Trả vải</div>
                        <Table columns={props.type != "web" ? col3s : col3Buttons} dataSource={lstFabricReturn} bordered pagination={false} />
                      </div>
                    ) : (
                      <></>
                    )}
                    {x.lstFabric && x.lstFabric.length > 0 ? (
                      <div style={{ marginTop: 10 }}>
                        <Card>
                          <Row gutter={20}>
                            {x.lstFabric.map((y) => {
                              return <Col span={8}>{`${y.fabric_name}: (${y.remaining_fabric})`}</Col>;
                            })}
                          </Row>
                        </Card>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          );
        })}
      </div>
      <PopupEditPay ref={popupEditPay} reload={props.getData} />
      <PopupEditWorkerTransaction ref={popupEditWorkerTransaction} reload={props.getData} />
    </div>
  );
});
export default WorkerHistoryPrint;
