import { Button, Form, Input, InputNumber, TimePicker, message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import { Print } from "component/print";
import dayjs from "dayjs";
import BillLive from "component/print/bill_live";
import api from "api";
import { useNavigate } from "react-router-dom";
import { Header } from "../../Client";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const Live = () => {
  const [time, setTime] = React.useState(0);
  const [count, setCount] = React.useState(false);
  const [data, setData] = React.useState({});
  const [mode, setMode] = React.useState("web");
  const [startTime, setStartTime] = React.useState();
  const [handleCount, setHandleCount] = React.useState(true);
  const timer = React.useRef();
  const form = React.useRef();
  const intervalCount = React.useRef();
  const printRef = React.useRef();
  const sessionRef = React.useRef();
  const workerRef = React.useRef(null);
  const { userLogin } = React.useContext(Header);
  const navigate = useNavigate();
  React.useEffect(() => {
    // Khởi tạo Web Worker
    workerRef.current = new Worker(new URL("./timer_worker.js", import.meta.url));

    workerRef.current.onmessage = (e) => {
      setTime(e.data.time);
      setCount(e.data.count);
      if (e.data.count && !sessionRef.current) {
        saveLiveSession();
      }

      if (!e.data.count && sessionRef.current) {
        endLiveSession();
      }
    };

    // Cleanup worker khi component unmount
    return () => {
      workerRef.current.postMessage("stop");
      workerRef.current.terminate();
    };
  }, []);

  const formatTime = (time) => {
    const getSeconds = `0${time % 60}`.slice(-2);
    const minutes = Math.floor(time / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(time / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  const onFinish = (values) => {
    if (!count) {
      message.error("chưa bắt đầu phiên live");
      return;
    }
    setData(values);
    setTimeout(() => {
      printRef.current.print();
    }, 500);
  };

  const beforePrint = () => {
    setMode("print");
  };
  const afterPrint = () => {
    setMode("web");
    saveOrder();
  };

  const saveLiveSession = () => {
    api.post("LiveOrder/SaveLiveSession").then((res) => {
      sessionRef.current = res.SessionID;
    });
  };

  const endLiveSession = () => {
    api.get("LiveOrder/EndLiveSession?SessionID=" + sessionRef.current).then((res) => {
      Modal.confirm({
        title: "Xác nhận",
        icon: <ExclamationCircleOutlined />,
        content: "Bạn có muốn xem đơn hàng của phiên live?",
        okText: "Đồng ý",
        cancelText: "Không",
        onOk: () => {
          navigate(`/orders/${sessionRef.current}`);
        },
        onCancel: () => {
          sessionRef.current = null;
        },
      });
    });
  };

  const saveOrder = () => {
    var dataOd = { ...data };
    dataOd.session_id = sessionRef.current;
    dataOd.create_by = userLogin.user_name;
    dataOd.time = formatTime(time);
    console.log(dataOd);
    api.post("LiveOrder/SaveOrderLive", dataOd).then((res) => {});
  };

  return (
    <div>
      {mode == "web" ? (
        <>
          <div style={{ textAlign: "center", fontSize: 20, color: "orange", fontWeight: "bold", marginBottom: 20 }}>{formatTime(time)}</div>
          <div style={{ marginBottom: 20, display: "flex", justifyContent: "center" }}>
            <TimePicker
              size="large"
              value={startTime}
              format={"HH:mm"}
              allowClear
              onChange={(e) => {
                setStartTime(e);
              }}
            ></TimePicker>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              style={{ marginRight: 20 }}
              onClick={() => {
                var data = { type: "count" };
                if (startTime) data.time = startTime.hour() * 3600 + startTime.minute() * 60;
                workerRef.current.postMessage(data);
                setStartTime();
              }}
            >
              {count ? "Stop" : "Start"}
            </Button>
            <Button
              style={{ marginRight: 20, marginBottom: 20 }}
              onClick={() => {
                workerRef.current.postMessage({ type: "reset" });
              }}
            >
              reset
            </Button>
            {/* <Button
              style={{ marginRight: 20, marginBottom: 20 }}
              onClick={() => {
                setTime((prevTime) => prevTime + moreTime * 60);
              }}
            >
              Thêm
            </Button> */}
          </div>
          <div style={{ marginRight: 20, marginLeft: 20 }}>
            <Form ref={form} layout="horizontal" onFinish={onFinish}>
              <Form.Item
                label="Tên"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
                labelCol={{
                  span: 3,
                }}
                wrapperCol={{
                  span: 21,
                }}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="Mã số"
                name="product_id"
                rules={[
                  {
                    required: true,
                  },
                ]}
                labelCol={{
                  span: 3,
                }}
                wrapperCol={{
                  span: 21,
                }}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="Giá"
                name="price"
                labelCol={{
                  span: 3,
                }}
                wrapperCol={{
                  span: 21,
                }}
              >
                <InputNumber
                  style={{ width: 300 }}
                  inputMode="numeric"
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  step={1000}
                  size="large"
                />
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Print
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
          <div style={{ display: "none" }}>
            <Print type="bill_live" data={data} time={time} ref={printRef} beforePrint={beforePrint} afterPrint={afterPrint} />
          </div>
        </>
      ) : (
        <BillLive data={data} time={time} />
      )}
    </div>
  );
};
export default Live;
