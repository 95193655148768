import React from "react";
import { Modal, Form, Input, message, Button, Checkbox, Select, InputNumber } from "antd";
import api from "api";
import AddressPicker from "./address_picker";

const JATCancelOrderPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [orderID, setOrderID] = React.useState();
  const form = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setOrderID(id);
    },
  }));

  const onFinish = (values) => {
    setLoading(true);
    api
      .get(`LiveOrder/CancelOrderToJAT?id=${orderID}&note=${values.note}`)
      .then((res) => {
        message.success("Hủy thành công");
        setVisible(false);
        setLoading(false);
        close();
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  const close = () => {
    form.current.resetFields();
    props.reload();
    setVisible(false);
  };

  return (
    <Modal
      maskClosable={false}
      width={600}
      open={visible}
      title={"Hủy đơn J&T"}
      onCancel={() => {
        close();
      }}
      onOk={() => {
        form.current.submit();
      }}
      okText="Sửa"
      cancelText="Thoát"
      footer={[
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            form.current.submit();
          }}
        >
          Hủy đơn
        </Button>,
      ]}
    >
      <Form layout="vertical" ref={form} onFinish={onFinish}>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="note"
          label="Ghi chú"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default JATCancelOrderPopup;
