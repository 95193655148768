import * as React from "react";
import api from "api";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { Col, Row, Avatar, Dropdown, Button } from "antd";
import { useParams, Outlet, useLocation } from "react-router-dom";
import "./index.css";

export const Header = React.createContext();
const Admin = (props) => {
  const [isLogin, setIsLogin] = React.useState(false);
  const [userLogin, setUserLogin] = React.useState({});
  const [wholesale, setwholesale] = React.useState(false);
  const [header, setHeader] = React.useState("Trang Võ");
  const [showHeader, setShowHeader] = React.useState(true);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    checkLogin();
    if (id) {
      var url = "GetByID?id=" + id;
      if (window.location.href.includes("customer")) url = "Customer/" + url;
      if (window.location.href.includes("source")) url = "Supplier/" + url;
      if (window.location.href.includes("worker")) url = "Worker/" + url;
      api.get(url).then((res) => {
        setHeader(res.data.name);
        if (window.location.href.includes("customer")) setwholesale(res.data.is_wholesale);
        else setwholesale(false);
      });
    } else {
      if (isLogin) {
        setHeader(userLogin.name + " " + (userLogin.position ?? ""));
      } else setHeader("Trang võ");
    }
  }, [location, isLogin]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get("name");
    if (name) setHeader(name);
    if (window.location.pathname == "/") {
      setHeader("Trang Võ");
      setwholesale(false);
    }
    if (window.location.pathname == "/customer_info") {
      setHeader(" Thêm khách hàng");
      setwholesale(false);
    }
  }, [location]);

  const checkLogin = () => {
    api
      .get("User/me")
      .then((res) => {
        setUserLogin(res.data);
        setIsLogin(true);
      })
      .catch((err) => {
        setIsLogin(false);
      });
  };

  const logOut = () => {
    api.get("/Login/Logout").then((res) => {
      navigate("/login");
    });
  };

  return (
    <Header.Provider value={{ userLogin: userLogin, header: header, setShowHeader: setShowHeader }}>
      <div>
        <Row>
          <Col xs={0} sm={0} md={0} lg={8} xl={8}></Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} style={showHeader ? { minHeight: "100vh" } : { minHeight: "100vh", backgroundColor: "#ffffff" }}>
            {showHeader && location.pathname != "/login" && location.pathname != "/live" ? (
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 20,
                  textAlign: "center",
                  padding: 20,
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #dfdfdf",
                  position: "relative",
                }}
              >
                {wholesale ? `${header} (sỉ)` : `${header}`}
                {location.pathname != "/login" ? (
                  <div style={{ position: "absolute", right: 10, top: 15 }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        logOut();
                      }}
                    >
                      <LogoutOutlined />
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            <div
              style={{
                padding: 10,
                boxSizing: "border-box",
                position: "relative",
                display: "flex",
                flexFlow: "column",
                minHeight: "100vh",
              }}
            >
              <div>
                <Outlet />
              </div>
            </div>
          </Col>
          <Col xs={0} sm={0} md={0} lg={8} xl={8}></Col>
        </Row>
      </div>
    </Header.Provider>
  );
};

export default Admin;
