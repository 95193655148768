import React from "react";
import { Modal, Form, Input, message, Button, Checkbox, Select, InputNumber, Flex } from "antd";
import api from "api";
import print from "print-js";
import { serverDomain } from "config";

const JATCreateOrderPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [orderID, setOrderID] = React.useState();
  const form = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setOrderID(id);
      setTimeout(() => {
        api.get("ShopConfig/GetConfig").then((res) => {
          if (res.data) {
            form.current.setFieldsValue(res.data);
          } else {
            form.current.setFieldsValue({ jat_partsign: false, jat_is_insured: false });
          }
        });
      }, 500);
    },
  }));

  const onFinish = (values) => {
    setLoading(true);
    values.order_id = orderID;
    api
      .post("LiveOrder/SendOrderToJAT", values)
      .then((res) => {
        message.success("Gửi thành công");
        props.printBillJAT(res.data);
        setVisible(false);
        setLoading(false);
        close();
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  const close = () => {
    form.current.resetFields();
    props.reload();
    setVisible(false);
  };

  return (
    <Modal
      maskClosable={false}
      width={600}
      open={visible}
      title={"Đẩy đơn J&T"}
      onCancel={() => {
        close();
      }}
      onOk={() => {
        form.current.submit();
      }}
      okText="Sửa"
      cancelText="Thoát"
      footer={[
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            form.current.submit();
          }}
        >
          Gửi
        </Button>,
      ]}
    >
      <Form layout="vertical" ref={form} onFinish={onFinish}>
        <div style={{ display: "flex", gap: 20 }}>
          <Form.Item
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
            name="jat_service_type"
            label="Gửi hàng"
          >
            <Select
              options={[
                {
                  label: "Gửi tại bưu cục",
                  value: 6,
                },
                {
                  label: "Shiper thu tận nơi",
                  value: 1,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
            name="jat_special"
            label="Dịch vụ"
          >
            <Select
              options={[
                {
                  label: "Dịch vụ tiêu chuẩn",
                  value: "EXPRESS",
                },
                {
                  label: "Dịch vụ chuyển nhanh",
                  value: "FAST",
                },
                {
                  label: "Dịch vụ hỏa tốc",
                  value: "SUPER",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 20 }}>
          <Form.Item
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
            name="jat_is_insured"
            label="Có bảo hiểm"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
            name="jat_partsign"
            label="Giao hàng 1 phần"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
});
export default JATCreateOrderPopup;
