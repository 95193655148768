const api_url = window.config.api_url;
const imageURL = window.config.imageURL;
const uploadImageURL = window.config.uploadImageURL;
const myDomain = window.config.myDomain;
const serverDomain = window.config.serverDomain;
const documentURL = window.config.documentURL;

const sourceToken = "tokendctaotutimekeeping";

const lstMenu = [
  {
    isLogin: false,
    text: "Chấm công",
    url: "/timekeeping",
  },
  {
    isLogin: true,
    text: "Quản lý chấm công",
    url: "/dashboard",
  },
  // {
  //   isLogin: true,
  //   text: "Quản lý nhân sự",
  //   url: "/employee",
  // },
  // {
  //   isLogin: true,
  //   text: "Quản lý công trình",
  //   url: "/location",
  // },
  // {
  //   isLogin: true,
  //   text: "Quản lý tài khoản",
  //   url: "/user",
  // },
];

const listTypeLocation = [
  {
    label: "Văn phòng",
    value: "VP",
  },
  {
    label: "Công trường",
    value: "CT",
  },
];

const listTypeTimekeepingCT = [
  {
    label: "Sáng",
    shift: "morning",
    type: "checkin",
    backgroundColor: "#c9f1fd",
    field: "in_morning",
  },
  {
    label: "Sáng",
    shift: "morning",
    type: "checkout",
    backgroundColor: "#c9f1fd",
    field: "out_morning",
  },
  {
    label: "Chiều",
    shift: "afternoon",
    type: "checkin",
    backgroundColor: "#fef8a0",
    field: "in_afternoon",
  },
  {
    label: "Chiều",
    shift: "afternoon",
    type: "checkout",
    backgroundColor: "#fef8a0",
    field: "out_afternoon",
  },
  {
    label: "Tối",
    shift: "evening",
    type: "checkin",
    backgroundColor: "#ffb4af",
    field: "in_evening",
  },
  {
    label: "Tối",
    shift: "evening",
    type: "checkout",
    backgroundColor: "#ffb4af",
    field: "out_evening",
  },
];
const listTypeTimekeepingVP = [
  {
    label: "Vào",
    shift: "day",
    type: "checkin",
    backgroundColor: "#c9f1fd",
    field: "in_morning",
  },
  {
    label: "Ra",
    shift: "day",
    type: "checkout",
    backgroundColor: "#c9f1fd",
    field: "out_evening",
  },
];

const listPrefixEmployeeCode = [
  { label: " ", value: "none" },
  { label: "CNN", value: "CNN" },
  { label: "TV", value: "TV" },
  { label: "TT", value: "TT" },
];
export {
  api_url,
  imageURL,
  uploadImageURL,
  myDomain,
  lstMenu,
  listTypeLocation,
  listTypeTimekeepingCT,
  listTypeTimekeepingVP,
  sourceToken,
  listPrefixEmployeeCode,
  serverDomain
};
