import React from "react";
import { formatCurrency } from "common/Format2";
import { CloseOutlined } from "@ant-design/icons";
import { Header } from "Client";
import dayjs from "dayjs";

const Bill = React.forwardRef((props, ref) => {
  const { lastTransaction, lstPurchase, lstReturn, payData, type, header } = props;
  return (
    <div ref={ref} style={type == "web" ? { backgroundColor: "#ffffff", padding: 10, borderRadius: 10 } : {}}>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <img src="/image/logo.jpg" style={{ width: "40%", borderRadius: 5 }} />
      </div>
      <div
        style={{
          marginBottom: 20,
          fontWeight: "bold",
          borderTop: "2px solid",
          borderBottom: "2px solid",
          padding: "5px 0",
          display: "flex",
          justifyContent: "space-between",
          fontSize: 11,
        }}
      >
        <div>
          <div>Địa chỉ: 44 Dương Tử Giang p14 q5 TPHCM</div>
          <div>Số điện thoại 1: 0903.102.988(Trang)</div>
          <div>Số điện thoại 1: 0909.408.685(Điền)</div>
        </div>
        <div>
          <div>Phương thức thanh toán:</div>
          <div>Chủ tài khoản: </div>
          <div>Hoàng Duy</div>
        </div>
        <div>
          <div>Sacombank: 0602.352.766.85</div>
          <div>Agribank: 6221.205.193.813</div>
          <div>VPbank: 128.685.502</div>
        </div>
      </div>
      <div style={{ marginBottom: 20 }}>
        {type != "web" ? (
          <div
            style={{
              fontWeight: "bold",
              fontSize: 20,
              textAlign: "center",
              backgroundColor: "#fff",
            }}
          >
            <div>{dayjs().format("DD/MM/YYYY")}</div>
            <div>{header}</div>
          </div>
        ) : (
          <></>
        )}
        <div style={{ marginBottom: 20 }}>
          {lstPurchase.length != 0 ? (
            <div>
              {lstPurchase.map((x, index) => {
                return (
                  <div style={{ marginBottom: 10 }}>
                    <div style={{ fontWeight: "bold" }}>{x.goods_type == "clothes" ? x.product_name : x.fabric_name}</div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        {x.goods_type == "clothes" ? (
                          x.quantity
                        ) : (
                          <>
                            {`${x.quantity}`} &nbsp;&nbsp;
                            <CloseOutlined />
                            &nbsp;&nbsp; {`${x.fabric_height}m`}
                          </>
                        )}
                      </div>
                      <div>
                        <CloseOutlined />
                      </div>
                      <div>{formatCurrency(x.price)}đ</div>
                      <div>=</div>
                      <div>{formatCurrency(x.quantity * x.price * (x.fabric_height ?? 1))}đ</div>
                    </div>
                  </div>
                );
              })}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                Tổng cộng: &nbsp;
                {formatCurrency(
                  lstPurchase.reduce((tong, x) => {
                    return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                  }, 0)
                )}
                đ
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <hr />
        <div style={{ padding: "0 20px", display: "flex", justifyContent: "flex-end" }}>
          <div style={{ minWidth: 280 }}>
            <div style={{ marginLeft: 100, display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%", float: "right" }}>
                <div>Sổ cũ</div>
                <div>Thanh toán</div>
                <div>Trả hàng</div>
              </div>
              <div style={{ width: "48%", float: "left" }}>
                <div style={lastTransaction && lastTransaction.profit < 0 ? { color: "red" } : {}}>
                  {lastTransaction && lastTransaction.profit != 0 ? formatCurrency(lastTransaction.profit) : 0}đ
                </div>

                {payData.price != 0}
                <div style={payData.price != null && payData.price != 0 ? { color: "red" } : {}}>{formatCurrency(payData.price)}đ</div>
                <div
                  style={
                    lstReturn.reduce((tong, x) => {
                      return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                    }, 0) != 0
                      ? { color: "red" }
                      : {}
                  }
                >
                  {formatCurrency(
                    lstReturn.reduce((tong, x) => {
                      return tong + x.price * x.quantity;
                    }, 0)
                  )}
                  đ
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <hr style={{ width: 230 }} />
              </div>
            </div>

            <div style={{ marginLeft: 100, display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%", float: "right" }}>Tổng</div>
              <div
                style={
                  lstPurchase.reduce((tong, x) => {
                    return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                  }, 0) +
                    (lastTransaction && lastTransaction.profit != 0 ? lastTransaction.profit : 0) -
                    (payData.price ?? 0) -
                    lstReturn.reduce((tong, x) => {
                      return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                    }, 0) >=
                  0
                    ? { width: "48%", float: "left" }
                    : { width: "48%", float: "left", color: "red" }
                }
              >
                {formatCurrency(
                  lstPurchase.reduce((tong, x) => {
                    return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                  }, 0) +
                    (lastTransaction && lastTransaction.profit != 0 ? lastTransaction.profit : 0) -
                    (payData.price ?? 0) -
                    lstReturn.reduce((tong, x) => {
                      return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                    }, 0)
                )}
                đ
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default Bill;
