import React from "react";
import { Row, Col, Form, Input, message, InputNumber, Button } from "antd";
import api from "api";
import { UserOutlined, LockOutlined, HomeOutlined } from "@ant-design/icons";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";

const Login = (props) => {
  const form = React.useRef();
  let navigate = useNavigate();

  /**
   * đăng nhập
   *
   * @param {Object} values - thông tin login .
   */
  const onFinish = (values) => {
    api
      .post("Login/Login", {
        user_name: values.username,
        password: values.password,
      })
      .then(async (res) => {
        if (res.code == 1) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <img src="/image/logo.jpg" style={{ width: "60%", borderRadius: 5 }} />
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center", height: "40vh", minHeight: 200 }}>
        <div style={{ margin: "auto" }}>
          <Form name="login" wrapperCol={{ span: 24 }} initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off" ref={form}>
            <div style={{ fontWeight: "bold", width: 300, textAlign: "center" }}>Luôn nhớ: mình là doanh nghiệp, không phải tiểu thương</div>
            <Form.Item name="username" rules={[{ required: true, message: "Nhập tên đăng nhập!" }]}>
              <Input prefix={<UserOutlined />} style={{ width: 300 }} placeholder="Tên đăng nhập" size="large" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: "Nhập mật khẩu!" }]}>
              <Input.Password prefix={<LockOutlined />} style={{ width: 300 }} placeholder="Mật khẩu" size="large" />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Login
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default Login;
