import React from "react";
import { Modal, message, Input, InputNumber, Button } from "antd";
import dayjs from "dayjs";
import { Timeline } from "antd";
import { formatCurrency, formatLongDate } from "common/Format2";

const PopupShowDebtCustomer = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [data1, setData1] = React.useState([]);
  const [data2, setData2] = React.useState([]);
  const [data3, setData3] = React.useState([]);
  const [data4, setData4] = React.useState([]);
  const [data5, setData5] = React.useState([]);

  React.useImperativeHandle(ref, () => ({
    open(data) {
      var date1 = dayjs().add(-1, "month");
      var date2 = dayjs().add(-2, "month");
      var date3 = dayjs().add(-3, "month");
      var date4 = dayjs().add(-4, "month");
      var date4 = dayjs().add(-4, "month");
      setData1(data.filter((x) => dayjs(x.debt_day) <= date1 && dayjs(x.debt_day) > date2));
      setData2(data.filter((x) => dayjs(x.debt_day) <= date2 && dayjs(x.debt_day) > date3));
      setData3(data.filter((x) => dayjs(x.debt_day) <= date3 && dayjs(x.debt_day) > date4));
      setData4(data.filter((x) => dayjs(x.debt_day) <= date4));

      setVisible(true);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      onCancel={() => {
        setVisible(false);
      }}
      width={600}
      open={visible}
      title={"Liệt kê thanh toán"}
      footer={
        <Button
          type="primary"
          onClick={() => {
            setVisible(false);
          }}
        >
          Thoát
        </Button>
      }
    >
      {data1.length != 0 ? (
        <div>
          <div style={{ fontWeight: "bold" }}>Chưa thanh toán 1 tháng</div>
          <div style={{ marginLeft: 10 }}>
            <Timeline
              items={data1.map((x) => {
                return {
                  children: `${x.customer_name}: ngày cuối thanh toán ${dayjs(x.debt_day).format("DD/MM/YYYY HH:mm")}- Số tiền: ${formatCurrency(x.profit)}`,
                };
              })}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {data2.length != 0 ? (
        <div>
          <div style={{ fontWeight: "bold" }}>Chưa thanh toán 2 tháng</div>
          <div style={{ marginLeft: 10 }}>
            <Timeline
              items={data2.map((x) => {
                return {
                  children: `${x.customer_name}: ngày cuối thanh toán ${dayjs(x.debt_day).format("DD/MM/YYYY HH:mm")}- Số tiền: ${formatCurrency(x.profit)}`,
                };
              })}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {data3.length != 0 ? (
        <div>
          <div style={{ fontWeight: "bold" }}>Chưa thanh toán 3 tháng</div>
          <div style={{ marginLeft: 10 }}>
            <Timeline
              items={data3.map((x) => {
                return {
                  children: `${x.customer_name}: ngày cuối thanh toán ${dayjs(x.debt_day).format("DD/MM/YYYY HH:mm")}- Số tiền: ${formatCurrency(x.profit)}`,
                };
              })}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {data4.length != 0 ? (
        <div>
          <div style={{ fontWeight: "bold" }}>Nợ xấu</div>
          <div style={{ marginLeft: 10 }}>
            <Timeline
              items={data4.map((x) => {
                return {
                  children: `${x.customer_name}: ngày cuối thanh toán ${dayjs(x.debt_day).format("DD/MM/YYYY HH:mm")}- Số tiền: ${formatCurrency(x.profit)}`,
                };
              })}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </Modal>
  );
});
export default PopupShowDebtCustomer;
