import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Client from "./Client";
import Admin2 from "Client2";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Home from "component/home";
import CustomerHistory from "component/customer/customer_history";
import CustomerInfo from "component/customer/customer_info";
import CustomerTransaction from "component/customer";
import SourceHistory from "component/source/source_history";
import SourceTransaction from "component/source";
import Worker from "component/worker";
import WorkerHistory from "component/worker/worker_history";
import ProtectedRoute from "protected-route";
import Login from "login";
import User from "user";
import WorkerFabricHistory from "component/worker/worker_fabric_history";
import Report from "component/report";
import Live from "component/live";
import Orders from "component/live/orders";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route element={<Client />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/customer_history/:id" element={<CustomerHistory />} />
          <Route exact path="/customer_info" element={<CustomerInfo />} />
          <Route exact path="/customer_info/:id" element={<CustomerInfo />} />
          <Route exact path="/customer_transaction/:id" element={<CustomerTransaction />} />
          <Route exact path="/source_history/:id" element={<SourceHistory />} />
          <Route exact path="/source_transaction/:id" element={<SourceTransaction />} />
          <Route exact path="/worker/:id" element={<Worker />} />
          <Route exact path="/worker_history/:id" element={<WorkerHistory />} />
          <Route exact path="/worker_fabric_history/:id" element={<WorkerFabricHistory />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/user" element={<User />} />
          <Route exact path="/report" element={<Report />} />
          <Route exact path="/live" element={<Live />} />
        </Route>
        
        <Route element={<Admin2 />}>
          <Route exact path="/orders" element={<Orders />} />
          <Route path="/orders/:session_id" element={<Orders />} />
        </Route>
      </Route>
    </Routes>
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
