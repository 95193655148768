import React from "react";
import { Input, Radio, Modal, Card, Button, InputNumber, Select, message } from "antd";
import api from "api";
import { useParams } from "react-router-dom";
import { createSearchText } from "common/Text";

const PopupEditPurchase = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [type, setType] = React.useState();
  const [title, setTitle] = React.useState();
  const [data, setData] = React.useState({});
  const [lstFabric, setLstFabric] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { id } = useParams();

  React.useEffect(() => {
    api.get("Fabric/GetList").then((res) => {
      setLstFabric(res.data);
    });
  }, []);

  React.useImperativeHandle(ref, () => ({
    open(type, data) {
      data.price = Math.abs(data.price);
      switch (type) {
        case "Customer":
          setTitle("Sửa sổ khách hàng");
          break;
        case "Supplier":
          setTitle("Sửa sổ đầu vào");
          data.goods_type = "fabric";
          break;

        default:
          break;
      }
      setData(data);
      setType(type);
      setVisible(true);
    },
  }));

  const dataChange = (value, name) => {
    var tran = { ...data };
    if (name == "fabric_id") {
      tran.fabric_name = value.label;
      tran.fabric_id = value.value;
    } else tran[name] = value;
    setData(tran);
  };
  console.log(props);

  const submit = () => {
    if (data.goods_type == "clothes" && (!data.product_name || data.product_name == "")) {
      message.error("Vui lòng nhập tên sản phẩm");
      return;
    }
    if (data.goods_type == "fabric" && !data.fabric_id) {
      message.error("Vui lòng chọn loại vải");
      return;
    }
    if (data.goods_type == "fabric" && type == "Customer" && !data.fabric_height) {
      message.error("Vui lòng nhập mét vải");
      return;
    }
    if (!data.quantity) {
      message.error("Vui lòng nhập số lượng");
      return;
    }
    if (!data.price) {
      message.error("Vui lòng nhập giá");
      return;
    }
    setLoading(true);
    api
      .post(`/${type}/UpdateTransaction`, data)
      .then((res) => {
        setLoading(false);
        message.success("Lưu thành công");
        props.reload();
        setVisible(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Lưu thất bại");
      });
  };

  return (
    <Modal
      maskClosable={false}
      width={400}
      open={visible}
      title={title}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        submit();
      }}
      okText="Sửa"
      cancelText="Thoát"
      footer={[
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            submit();
          }}
        >
          Sửa
        </Button>,
      ]}
    >
      <div>
        {type == "Customer" ? (
          <div style={{ marginBottom: 20 }}>
            <Radio.Group
              size="large"
              name="radiogroup"
              value={data.goods_type}
              onChange={(e) => {
                dataChange(e.target.value, "goods_type");
              }}
            >
              <Radio value={"clothes"}>Mua áo</Radio>
              <Radio value={"fabric"}>Mua vải</Radio>
            </Radio.Group>
          </div>
        ) : (
          <></>
        )}

        <div style={{ marginBottom: 20 }}>
          {data.goods_type == "clothes" ? (
            <Input
              placeholder="Tên sản phẩm"
              size="large"
              value={data.product_name}
              onChange={(e) => {
                dataChange(e.target.value, "product_name");
              }}
            ></Input>
          ) : (
            <Select
              placeholder="Chọn loại vải"
              size="large"
              style={{
                width: "100%",
              }}
              options={lstFabric.map((x) => {
                return {
                  label: x.name,
                  value: x.id,
                };
              })}
              showSearch={true}
              autoClearSearchValue={true}
              filterOption={(key, option) => {
                key = createSearchText(key);
                var label = createSearchText(option.name);
                if (label.includes(key)) return true;
                return false;
              }}
              virtual={false}
              value={data.fabric_id}
              onChange={(e, f) => {
                dataChange(f, "fabric_id");
              }}
            />
          )}
        </div>
        <div style={{ marginBottom: 20 }}>
          <InputNumber
            inputMode="numeric"
            min={0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            step={1000}
            size="large"
            placeholder="Số lượng"
            style={{ marginRight: 10, marginBottom: 10 }}
            value={data.quantity}
            onChange={(e) => {
              dataChange(e, "quantity");
            }}
          ></InputNumber>
          {data.goods_type == "fabric" ? (
            <InputNumber
              inputMode="numeric"
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              step={1000}
              size="large"
              placeholder="Mét vải"
              style={{ marginRight: 10, marginBottom: 10 }}
              value={data.fabric_height}
              onChange={(e) => {
                dataChange(e, "fabric_height");
              }}
            ></InputNumber>
          ) : (
            <></>
          )}
          <InputNumber
            inputMode="numeric"
            min={0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            step={1000}
            size="large"
            placeholder="Giá"
            style={{ width: 150 }}
            value={data.price}
            onChange={(e) => {
              dataChange(e, "price");
            }}
          ></InputNumber>
        </div>
      </div>
    </Modal>
  );
});
export default PopupEditPurchase;
