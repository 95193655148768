import React from "react";
import { Card, Button, Select, message, Popconfirm, Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import api from "api";
import PopupInsert from "./popup_insert";
import PopupShowDebtCustomer from "./popup_show_debt_customer";
import { Header } from "Client";
import { createSearchText } from "common/Text";

const Home = (props) => {
  const [customer, setCustomer] = React.useState();
  const [worker, setWorker] = React.useState();
  const [source, setSource] = React.useState();
  const [lstCustomer, setLstCustomer] = React.useState([]);
  const [cusBirthDays, setCusBirthdays] = React.useState([]);
  const [lstSupplier, setLstSupplier] = React.useState([]);
  const [lstWorker, setLstWorker] = React.useState([]);
  const [loadingCustomer, setLoadingCustomer] = React.useState(true);
  const [loadingSupplier, setLoadingSupplier] = React.useState(true);
  const [loadingWorker, setLoadingWorker] = React.useState(true);
  const [lstCustomerDebt, setLstCustomerDebt] = React.useState([]);
  const [type, setType] = React.useState();

  const popupInsert = React.useRef();
  const popupShowDebtCustomer = React.useRef();
  const { userLogin, header } = React.useContext(Header);
  console.log("userLogin", userLogin);
  const navigate = useNavigate();

  React.useEffect(() => {
    getListCustomer();
    getListSupplier();
    getListWorker();
    api.get("Customer/GetCustomerWithBirthDayToDay").then((res) => {
      setCusBirthdays(res.data);
    });
    api.get("Customer/GetCustomerDebt").then((res) => {
      setLstCustomerDebt(res.data);
    });
  }, []);

  const getListCustomer = () => {
    api.get("Customer/GetList").then((res) => {
      setLstCustomer(res.data);
      setLoadingCustomer(false);
    });
  };

  const getListSupplier = () => {
    api.get("Supplier/GetList").then((res) => {
      setLstSupplier(res.data);
      setLoadingSupplier(false);
    });
  };

  const getListWorker = () => {
    api.get("Worker/GetList").then((res) => {
      setLstWorker(res.data);
      setLoadingWorker(false);
    });
  };

  const deleteItem = (type, id) => {
    api.get(`${type}/Delete?id=${id}`).then((res) => {
      message.success("Xóa thành công");
      if (type == "Supplier") {
        setSource(null);
        getListSupplier();
      } else {
        setWorker(null);
        getListWorker();
      }
    });
  };

  return (
    <div>
      <div style={{ marginBottom: 20, fontWeight: "bold" }}>
        <div>{`Chào mừng ${userLogin.position && userLogin.position != "" ? userLogin.position : ""} ${userLogin.name}.`}</div>
        <div>Chúc bạn 1 ngày làm việc hiệu quả, bạn muốn làm gì?</div>
      </div>

      <Card style={{ marginBottom: 20 }}>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Button
              style={{ width: "100%" }}
              size="large"
              type="primary"
              onClick={() => {
                setType("KhachHangSi");
              }}
            >
              Tạo đơn khách sỉ
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={{ width: "100%" }}
              size="large"
              type="primary"
              onClick={() => {
                setType("KhachHangLe");
              }}
            >
              Tạo đơn khách lẻ
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={{ width: "100%" }}
              size="large"
              type="primary"
              onClick={() => {
                setType("DauVao");
              }}
            >
              Đầu vào
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={{ width: "100%" }}
              size="large"
              type="primary"
              onClick={() => {
                setType("Tho");
              }}
            >
              Thợ
            </Button>
          </Col>
        </Row>
      </Card>
      {type == "KhachHangLe" ? (
        <Card style={{ marginBottom: 20 }} title={<div style={{ fontWeight: "bold", fontSize: 20, color: "red" }}>Khách hàng lẻ</div>}>
          <div style={{ fontWeight: "bold", marginBottom: 10 }}>Chọn khách hàng</div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <div style={{ marginRight: 20, flex: 1 }}>
              <Select
                loading={loadingCustomer}
                size="large"
                style={{
                  width: "100%",
                }}
                onChange={(e, f) => {
                  setCustomer(f);
                }}
                options={lstCustomer
                  .filter((x) => x.is_wholesale != true)
                  .map((x) => {
                    return { label: x.name, value: x.id };
                  })}
                value={customer?.value}
                showSearch={true}
                autoClearSearchValue={true}
                filterOption={(key, option) => {
                  key = createSearchText(key);
                  var label = createSearchText(option.label);
                  if (label.includes(key)) return true;
                  return false;
                }}
                virtual={false}
              />
            </div>
            <div>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  if (customer) {
                    navigate(`/customer_transaction/${customer.value}`);
                  } else message.error("Vui lòng chọn khách hàng");
                }}
              >
                Tìm
              </Button>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                navigate("/customer_info");
              }}
            >
              Thêm khách hàng mới
            </Button>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type == "KhachHangSi" ? (
        <Card style={{ marginBottom: 20 }} title={<div style={{ fontWeight: "bold", fontSize: 20, color: "red" }}>Khách hàng sỉ</div>}>
          <div style={{ fontWeight: "bold", marginBottom: 10 }}>Chọn khách hàng</div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <div style={{ marginRight: 20, flex: 1 }}>
              <Select
                loading={loadingCustomer}
                size="large"
                style={{
                  width: "100%",
                }}
                onChange={(e, f) => {
                  setCustomer(f);
                }}
                options={lstCustomer
                  .filter((x) => x.is_wholesale == true)
                  .map((x) => {
                    return { label: x.name, value: x.id };
                  })}
                value={customer?.value}
                showSearch={true}
                autoClearSearchValue={true}
                filterOption={(key, option) => {
                  key = createSearchText(key);
                  var label = createSearchText(option.label);
                  if (label.includes(key)) return true;
                  return false;
                }}
                virtual={false}
              />
            </div>
            <div>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  if (customer) {
                    navigate(`/customer_transaction/${customer.value}`);
                  } else message.error("Vui lòng chọn khách hàng");
                }}
              >
                Tìm
              </Button>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                navigate("/customer_info");
              }}
            >
              Thêm khách hàng mới
            </Button>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type == "DauVao" ? (
        <Card style={{ marginBottom: 20 }} title={<div style={{ fontWeight: "bold", fontSize: 20, color: "red" }}>Đầu vào</div>}>
          <div style={{ fontWeight: "bold", marginBottom: 10 }}>Chọn đầu vào</div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <div style={{ marginRight: 20, flex: 1 }}>
              <Select
                loading={loadingSupplier}
                size="large"
                style={{
                  width: "100%",
                }}
                onChange={(e, f) => {
                  setSource(f);
                }}
                value={source?.id}
                options={lstSupplier}
                fieldNames={{ value: "id", label: "name" }}
                showSearch={true}
                autoClearSearchValue={true}
                filterOption={(key, option) => {
                  key = createSearchText(key);
                  var label = createSearchText(option.name);
                  if (label.includes(key)) return true;
                  return false;
                }}
                virtual={false}
              />
            </div>
            <div>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  if (source) {
                    navigate(`/source_transaction/${source.id}`);
                  } else message.error("Vui lòng chọn đầu vào");
                }}
              >
                Tìm
              </Button>
            </div>
          </div>
          <div style={{ display: "flex", gap: 20, width: "100%" }}>
            <Button
              style={{ flex: 1 }}
              size="large"
              type="primary"
              onClick={() => {
                popupInsert.current.open("Supplier");
              }}
            >
              Thêm đầu vào mới
            </Button>
            <Button
              style={{ flex: 1 }}
              size="large"
              type="primary"
              onClick={() => {
                if (source) {
                  popupInsert.current.open("Supplier", source);
                } else message.error("Vui lòng chọn đầu vào");
              }}
            >
              Chỉnh sửa
            </Button>
            <Popconfirm
              style={{ flex: 1 }}
              title="Xóa đầu vào"
              description="Bạn có muôn xóa đầu vào này không?"
              onConfirm={() => {
                if (source) {
                  deleteItem("Supplier", source.id);
                } else message.error("Vui lòng chọn đầu vào");
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button size="large" type="primary" style={{ flex: 1 }}>
                Xóa
              </Button>
            </Popconfirm>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type == "Tho" ? (
        <Card style={{ marginBottom: 20 }} title={<div style={{ fontWeight: "bold", fontSize: 20, color: "red" }}>Thợ</div>}>
          <div style={{ fontWeight: "bold", marginBottom: 10 }}>Chọn thợ</div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <div style={{ marginRight: 20, flex: 1 }}>
              <Select
                loading={loadingWorker}
                size="large"
                style={{
                  width: "100%",
                }}
                value={worker?.id}
                fieldNames={{ value: "id", label: "name" }}
                onChange={(e, f) => {
                  setWorker(f);
                }}
                options={lstWorker}
                showSearch={true}
                autoClearSearchValue={true}
                filterOption={(key, option) => {
                  key = createSearchText(key);
                  var label = createSearchText(option.name);
                  if (label.includes(key)) return true;
                  return false;
                }}
                virtual={false}
              />
            </div>
            <div>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  if (worker) {
                    navigate(`/worker/${worker.id}`);
                  } else message.error("Vui lòng chọn thợ");
                }}
              >
                Tìm
              </Button>
            </div>
          </div>
          <div style={{ display: "flex", gap: 20, width: "100%" }}>
            <Button
              style={{ flex: 1 }}
              size="large"
              type="primary"
              onClick={() => {
                popupInsert.current.open("Worker");
              }}
            >
              Thêm thợ mới
            </Button>
            <Button
              style={{ flex: 1 }}
              size="large"
              type="primary"
              onClick={() => {
                if (worker) {
                  popupInsert.current.open("Worker", worker);
                } else message.error("Vui lòng chọn thợ");
              }}
            >
              Chỉnh sửa
            </Button>
            <Popconfirm
              style={{ flex: 1 }}
              title="Xóa thợ"
              description="Bạn có muôn xóa thợ này không?"
              onConfirm={() => {
                if (worker) {
                  deleteItem("Worker", worker.id);
                } else message.error("Vui lòng chọn thợ");
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button size="large" type="primary" style={{ flex: 1 }}>
                Xóa
              </Button>
            </Popconfirm>
          </div>
        </Card>
      ) : (
        <></>
      )}

      {cusBirthDays.length > 0 ? (
        <Card>
          <div>
            <b>Hôm nay là sinh nhật của khách hàng:</b>
          </div>
          <ul>
            {cusBirthDays.map((x) => {
              return <li>{x}</li>;
            })}
          </ul>
        </Card>
      ) : (
        <></>
      )}
      {lstCustomerDebt.length > 0 || userLogin.role_id == 1 ? (
        <Card>
          <div style={{ display: "flex", gap: 20 }}>
            <>
              {lstCustomerDebt.length > 0 ? (
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    popupShowDebtCustomer.current.open(lstCustomerDebt);
                  }}
                  style={{ flex: 1 }}
                >
                  Công nợ
                </Button>
              ) : (
                <></>
              )}
              {userLogin.role_id == 1 ? (
                <>
                  <Button
                    type="primary"
                    size="large"
                    style={{ flex: 1 }}
                    onClick={() => {
                      navigate("/user");
                    }}
                  >
                    Tài khoản
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    style={{ flex: 1 }}
                    onClick={() => {
                      navigate("/report");
                    }}
                  >
                    Báo cáo
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    style={{ flex: 1 }}
                    onClick={() => {
                      navigate("/live");
                    }}
                  >
                    Live
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    style={{ flex: 1 }}
                    onClick={() => {
                      navigate("/orders");
                    }}
                  >
                    Đơn hàng
                  </Button>
                </>
              ) : (
                <></>
              )}
            </>
          </div>
        </Card>
      ) : (
        <></>
      )}

      <PopupInsert ref={popupInsert} getListSupplier={getListSupplier} getListWorker={getListWorker} />
      <PopupShowDebtCustomer ref={popupShowDebtCustomer}></PopupShowDebtCustomer>
    </div>
  );
};
export default Home;
