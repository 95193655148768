import React from "react";
import { Modal, message, Input, InputNumber, Button } from "antd";
import api from "api";

const PopupInsert = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState({});
  const [type, setType] = React.useState();
  const [title, setTitle] = React.useState();
  const [loading, setLoading] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    open(type, data) {
      if (!data) setData({});
      else {
        api.get(`${type}/GetByID?id=` + data.id).then((res) => {
          setData(res.data);
        });
      }
      setType(type);
      switch (type) {
        case "Fabric":
          setTitle("Thêm loại vải");
          break;
        case "Supplier":
          setTitle("Thêm đầu vào");
          break;
        case "Worker":
          setTitle("Thêm thợ");
          break;

        default:
          break;
      }
      setVisible(true);
    },
  }));

  const add = () => {
    if (!data.name || data.name == "") {
      message.error("Vui lòng nhập tên");
      return;
    }
    setLoading(true);
    api
      .post(`/${type}/Save`, data)
      .then((res) => {
        message.success("Lưu thành công");
        props[`getList${type}`]();
        setVisible(false);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Lưu thất bại");
        setLoading(false);
      });
  };

  return (
    <Modal
      maskClosable={false}
      width={400}
      open={visible}
      title={title}
      onCancel={() => {
        setVisible(false);
      }}
      footer={[
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            add();
          }}
        >
          Lưu
        </Button>,
      ]}
      okText="Thêm"
      cancelText="Thoát"
    >
      <div style={{ fontWeight: "bold", marginBottom: 10 }}>Tên</div>
      <Input
        value={data.name}
        onChange={(e) => {
          var item = { ...data };
          item.name = e.target.value;
          setData(item);
        }}
        size="large"
      ></Input>
      {type == "Fabric" ? (
        <div style={{ marginTop: 20, width: "100%" }}>
          <div style={{ fontWeight: "bold", marginBottom: 10 }}>Giá</div>
          <InputNumber
            inputMode="numeric"
            min={0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            step={1000}
            size="large"
            placeholder="Giá"
            style={{ marginRight: 10, marginBottom: 10, width: "100%" }}
            value={data.price}
            onChange={(e) => {
              var item = { ...data };
              item.price = e;
              setData(item);
            }}
          ></InputNumber>
        </div>
      ) : (
        <></>
      )}
    </Modal>
  );
});
export default PopupInsert;
